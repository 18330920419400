import React from 'react';

// Supermove
import {Styled, Icon, Space} from '@supermove/components';
import {ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

import Button from '@shared/design/components/Button';

const Container = Styled.View`
  background-color: ${colors.white};
`;

const Line = Styled.View`
  width: 100%;
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${({disabled}: {disabled: boolean}) => (disabled ? colors.gray.tertiary : colors.white)};
`;

export interface JobAttachmentsV2FooterProps {
  disabled: boolean;
  submitting: boolean;
  handleSubmit: () => void;
  numberOfPhotos?: number;
  responsive: ResponsiveType;
}

const JobAttachmentsV2Footer = ({
  disabled,
  submitting,
  handleSubmit,
  numberOfPhotos = 0,
  responsive,
}: JobAttachmentsV2FooterProps) => {
  return (
    <Container>
      <Line />
      <Space height={24} />
      <Button
        isDisabled={disabled}
        isSubmitting={submitting}
        onPress={handleSubmit}
        isWidthOfContainer
        style={{alignItems: 'center', marginHorizontal: responsive.mobile ? 16 : '20%'}}
        text={`Save (${numberOfPhotos})`}
        iconLeft={Icon.Check}
        iconSize={16}
        isResponsive
      />
      <Space height={24} />
    </Container>
  );
};

export default JobAttachmentsV2Footer;
